.footer--container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.footer--content {
    color: white;
    text-align: center;
    font-size: 2vh;
}