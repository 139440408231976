.main--content {
    z-index: 2;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    min-width: 300px;
    background-image: url("https://l3xi3bux.s3.us-east-2.amazonaws.com/back_black9.png");
    border-radius: 25px;
}

.main--content h3 {
    text-align: center;
    font-size: max(48px, 6vw);
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 20px;
}

.main--content p {
    color: white;
    text-align: center;
}

.sub--contentWrap {
    width: 100%;
    height: auto;
    background-color: white;
}
.sub--content {
    max-width: 800px;
    height: auto;
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
}

.sub--content p {
margin-top: 20px;
margin-bottom: 10px;
}

.sub--content .subtext {
    text-align: center;
    font-size: 3vh;

}

.sub--content .minicon {
    text-align: center;
    font-size: 7vh;
}
  