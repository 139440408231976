.navBar--container {
    z-index: 2;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.navBar--container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navBar--container li {
    float: left;
}

.navBar--container li a {
    display: block;
    padding: 10px;
    margin: 0px;
    color: #ffffff;
    text-decoration: none;
    
}

.navBar--container li a:hover {
    background-color: cyan;
    
}