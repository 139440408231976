.videoContainer {
    position: relative;
    z-index: 0;
    width: 100%;
}

.videoContainer video {
    width: 100%;
    position: absolute;
}

@media (max-width : 900px) {
    .videoContainer {display:none;}
}