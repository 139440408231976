.big-red {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #000000;
    background-color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    border-radius: 25px;
    font-weight: bold;
    margin-bottom: 25px;
    border: #ffffff;
    border-width: 5px;
    border-style: solid;
}

.big-white {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #ffffff;
    background-color: none;
    font-size: 20px;
    text-decoration: none;
    border-radius: 25px;
    font-weight: bold;
    margin-bottom: 25px;
    border: #ffffff;
    border-width: 5px;
    border-style: solid;
}

